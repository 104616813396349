import React, { useRef, useState } from 'react';
import { BottleOutput } from '../../../types/WineStorage';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import { SimpleBottleOutputList } from './SimpleBottleOutputList';
import { ServiceError, StatusType } from '../../../../../../../types/Service';
import { useBottleOutputService } from '../../../hooks/useBottleOutputService';
import { useTranslation } from 'react-i18next';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { EntityLiveStatus } from '../../../../../../../components/common/enums/EntityLiveStatus';
import { useConfirmation } from '../../../../../../notifications/useConfirmation';
import log from 'loglevel';

export const BottleOutputList: React.FC<{ bottleOutputs: BottleOutput[] }> = ({
  bottleOutputs = []
}) => {
  const { setResult, wineStorage } = useWineStorageContext();
  const [error, setError] = useState<ServiceError>();
  const { t } = useTranslation();
  const serviceRef = useRef(useBottleOutputService());
  const [loading, setLoading] = useState(false);
  const [loadingTax, setLoadingTax] = useState(false);

  const showConfirmation = useConfirmation();

  const removeOutput = (entity: BottleOutput) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemove(entity)
    });
  };

  const handleRemove = (entity: BottleOutput) => {
    entity.hasTaxBands ? setLoadingTax(true) : setLoading(true);
    serviceRef.current
      .del(entity.id)
      .then((response) => {
        setResult({ status: StatusType.loaded, payload: response });
      })
      .then(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      })
      .catch((error) => {
        log.error(error);
        setError(error);
      })
      .finally(() => {
        entity.hasTaxBands ? setLoadingTax(false) : setLoading(false);
      });
  };

  const withTaxBands = bottleOutputs
    ?.filter((output) => output.hasTaxBands)
    ?.sort((a, b) => new Date(b.outputDate).getTime() - new Date(a.outputDate).getTime());
  const withoutTaxBands = bottleOutputs
    ?.filter((output) => !output.hasTaxBands)
    ?.sort((a, b) => new Date(b.outputDate).getTime() - new Date(a.outputDate).getTime());

  return (
    <>
      {withTaxBands?.length || loadingTax || wineStorage?.id == undefined ? (
        <SimpleBottleOutputList
          bottleOutputs={withTaxBands}
          loading={loadingTax || wineStorage?.id == undefined}
          removeOutput={removeOutput}
          title={t('wineStorage.bottle.output.withTaxBands')}
          removeDisabled={wineStorage.liveStatus === EntityLiveStatus.ARCHIVED}
        >
          <FormErrorMessage error={error} messageType={'details'} />
        </SimpleBottleOutputList>
      ) : null}
      {withoutTaxBands?.length || loading || wineStorage?.id == undefined ? (
        <SimpleBottleOutputList
          bottleOutputs={withoutTaxBands}
          loading={loading || wineStorage?.id == undefined}
          removeOutput={removeOutput}
          title={t('wineStorage.bottle.output.withoutTaxBands')}
          removeDisabled={wineStorage.liveStatus === EntityLiveStatus.ARCHIVED}
        >
          <FormErrorMessage error={error} messageType={'details'} />
        </SimpleBottleOutputList>
      ) : null}
    </>
  );
};
