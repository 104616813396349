import React from 'react';

export const PlusMinusComponent = ({
  value,
  plus,
  minus
}: {
  value: number;
  plus?: boolean;
  minus?: boolean;
}) => {
  if (plus)
    return (
      <span>
        <span className="text-success pr-1">+</span>
        {value}
      </span>
    );
  if (minus)
    return (
      <span>
        <span className="text-danger pr-1">-</span>
        {value}
      </span>
    );
  return <span>{value}</span>;
};
