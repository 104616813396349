import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { CompanyInfoData, UserData } from '../../types';
import useAdminService from '../../hooks/useAdminService';
import { UsersTable } from '../users/UsersTable';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../types/RouterParams';
import { CompanySummary } from './CompanySummary';
import { CompanySubscription } from './CompanySubscription';
import { CompanySubscriptionCard } from './CompanySubscriptionCard';
import { CompanyAccessInfoCard } from './CompanyAccessInfoCard';
import { CompanyPaymentInfoCard } from './CompanyPaymentInfoCard';
import { PaymentsTable } from '../payments/PaymentsTable';
import { SubscriptionsTable } from '../subscriptions/SubscriptionsTable';
import log from 'loglevel';

export const CompanyPage = () => {
  const [company, setCompany] = useState<CompanyInfoData>(null);
  const serviceRef = useRef(useAdminService());
  const [loading, setLoading] = useState(true);
  const { companyId } = useParams<RouterParams>();

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .get(companyId)
        .then((response) => {
          setCompany(response);
        })
        .finally(() => setLoading(false));
    }
  }, [loading, setCompany, companyId]);

  function deactivateCompany(id: number) {
    setLoading(true);
    serviceRef.current
      .deactivateCompany(id)
      .then((response) => {
        setCompany(response);
      })
      .catch((error) => log.error(error))
      .finally(() => setLoading(false));
  }

  function activateCompany(id: number) {
    setLoading(true);
    serviceRef.current
      .activateCompany(id)
      .then((response) => setCompany(response))
      .finally(() => setLoading(false));
  }

  function deactivateUser(id: number) {
    setLoading(true);
    serviceRef.current
      .deactivateUser(id)
      .then((response) => setCompany(response))
      .catch((error) => log.error(error))
      .finally(() => setLoading(false));
  }

  function activateUser(id: number) {
    setLoading(true);
    serviceRef.current
      .activateUser(id)
      .then((response) => setCompany(response))
      .catch((error) => log.error(error))
      .finally(() => setLoading(false));
  }

  function addNewUser(user: UserData) {
    return serviceRef.current
      .addNewUser(user)
      .then((response) => setCompany(response))
      .catch((error) => log.error(error));
  }

  if (company === null || company === undefined)
    return <PageWrapper title={'admin.PAGE'} loading={true} />;

  const {
    subscriptionInfo: { activeSubscription, inProgressSubscription, accessInfo, paymentInfo }
  } = company;

  return (
    <PageWrapper title={'admin.PAGE'}>
      <div className={'row'}>
        <div className="col-lg-4 col-md-6">
          <CompanySummary company={company} actions={{ activateCompany, deactivateCompany }} />
          {accessInfo ? (
            <CompanyAccessInfoCard accessInfo={accessInfo} title={'Informacje o dostępach'} />
          ) : null}
          {paymentInfo ? (
            <CompanyPaymentInfoCard paymentInfo={paymentInfo} title={'Aktualna płatność'} />
          ) : null}
          <CompanySubscription company={company} reload={setLoading} />
          {activeSubscription ? (
            <CompanySubscriptionCard subscription={activeSubscription} title={'Aktywna'} />
          ) : null}
          {inProgressSubscription ? (
            <CompanySubscriptionCard subscription={inProgressSubscription} title={'W trakcie'} />
          ) : null}
        </div>
        <div className="col-lg-8 col-md-6">
          <UsersTable
            users={company.users}
            loading={loading}
            actions={{ deactivateUser, activateUser, addNewUser }}
            key={company.users.length}
          />
          <PaymentsTable payments={company.payments} />
          <SubscriptionsTable subscriptions={company.subscriptions} reload={setLoading} />
        </div>
      </div>
    </PageWrapper>
  );
};
