import React, { FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import InputElement from '../../../../../../components/common/InputElement';
import { SelectProductionEventType } from './SelectProductionEventTypeType';
import { EventToWineProps } from './withProductionEventToWineServiceHOC';
import { ProductionEventForWineType } from '../../../wine/types/Wine';
import { InputDate } from '../../../../../../components/common/form-elements/InputDate';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { RouterParams } from '../../../../../../types/RouterParams';
import { useParams } from 'react-router-dom';
import { useResponsiveContext } from '../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../hooks/useToggleAndGoToRef';
import { DivDropped } from '../../../../../../components/common/dropdowns/button-and-div/DivDropped';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

export const ProductionEventFormFields: FC<EventToWineProps> = ({
  event,
  actions: { onChange, updateTypeSelect, updateDate, onSubmit, key },
  error,
  entity,
  loading
}) => {
  const { t } = useTranslation();
  const { productionEventId } = useParams<RouterParams>();

  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(!!productionEventId, isMobile);

  // function getEventStartingDate(event: ProductionEvent) {
  //   if (!event.startingDate) {
  //     event.startingDate = new Date();
  //   }
  //   return event.startingDate;
  // }

  return (
    <SectionWrapper loading={loading}>
      <Card
        key={key}
        innerRef={currentRef}
        className={isOpen ? 'border-info-dark form' : 'bg-info-dark form'}
      >
        <CardBody className={'pt-2 pb-1'}>
          <div
            onClick={() => toggleOpen()}
            style={{ cursor: 'pointer', height: '1.5em' }}
            className={'pt-1'}
          >
            <i className="float-left">
              <em
                className={
                  isOpen ? 'fa fa-minus text-primary text-gradient-blue-purple' : 'fa fa-plus'
                }
              />
            </i>
            <h5 className={'m-0 mb-2 float-right ' + (isOpen ? 'text-gradient-blue-purple' : '')}>
              {t(event?.id ? 'common.EDIT' : 'wine.info.ADD_NEW_EVENT')}
            </h5>
          </div>
          <DivDropped open={isOpen}>
            <div className={'mt-2'}>
              <SelectProductionEventType
                value={event?.type}
                name={'type'}
                label={t('add_ingredient.SELECT_TYPE')}
                onChange={updateTypeSelect}
                error={error}
                entity={entity}
              />
              {event?.type === ProductionEventForWineType.OTHER && (
                <InputElement
                  label={t('event.NAME')}
                  name={'name'}
                  defaultValue={event?.name}
                  onChange={onChange}
                  error={error}
                  optional
                />
              )}
              <InputDate
                label={t('event.STARTING_DATE')}
                name={'startingDate'}
                defaultValue={event.startingDate}
                onChange={updateDate}
                error={error}
                showTimeSelect
              />
              <InputDate
                label={t('event.ENDING_DATE')}
                name={'endingDate'}
                defaultValue={event?.endingDate}
                onChange={updateDate}
                error={error}
                optional
                showTimeSelect
              />
              <InputElement
                label={t('event.INFO')}
                type={'textarea'}
                name={'info'}
                defaultValue={event?.info}
                onChange={onChange}
                error={error}
                maxSize={'1000'}
                optional
              />
              <InputElement
                label={
                  event.type === ProductionEventForWineType.PRESS
                    ? t('event.PRESS.WASTE')
                    : t('event.WASTE')
                }
                type={'number'}
                name={'waste'}
                defaultValue={event?.waste}
                onChange={onChange}
                error={error}
                className={'mb-2'}
                optional
              />
              {event.type === ProductionEventForWineType.PRESS ? (
                <small className="text-muted">{t('event.PRESS.MESSAGE')}</small>
              ) : null}
              {(event?.type === ProductionEventForWineType.OTHER ||
                event?.type === ProductionEventForWineType.SUGARING ||
                event?.type === ProductionEventForWineType.ALCOHOL_INCREASING) && (
                <InputElement
                  label={t('event.INPUT')}
                  type={'number'}
                  name={'input'}
                  defaultValue={event?.input}
                  onChange={onChange}
                  error={error}
                  className={'mb-2 pt-2'}
                  optional
                />
              )}
              <FormErrorMessage error={error} messageType={'details'} />
              <div className={'bg-secondary text-center pb-0'}>
                <Button
                  color="info"
                  className="btn-square"
                  onClick={event?.id ? onSubmit?.update : onSubmit?.save}
                >
                  {event?.id ? t('common.SAVE') : t('common.ADD')}
                </Button>
              </div>
            </div>
          </DivDropped>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
