import { History } from '../../../wine/types/History';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContentWrapper from '../../../../../../layouts/ContentWrapper';
import TimelineSection from '../../../../../../components/common/Timeline/TimelineSection';
import TimelineElement from '../../../../../../components/common/Timeline/TimelineElement';
import PropTypes from 'prop-types';
import { Color } from '../../../../../../components/common/enums/Color';
import { FromApiConverter } from '../../../../../../services/Converters';
import { AuthContext } from '../../../../../platform/AuthContext';
import { SubscriptionType } from '../../../../../../types/Subscription';
import { LockArea } from '../../../../../../components/common/lock/LockArea';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { GrapeColor } from '../../../grapevine/types/GrapeColor';

interface Props {
  history?: History[];
  loading: boolean;
  grapeColor: GrapeColor;
}

const HarvestHistory: React.FC<Props> = ({ history, loading, grapeColor }) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  function getFallbackInfo() {
    return !accessInfo.hasNormalAccess ? (
      <LockArea
        subscriptionTypeNeeded={SubscriptionType.NORMAL}
        blockingMessage={'Przejdź na wyższy plan, aby wyświetlić historię zbiorów'}
        label={'Historia utworzonych moszczy dostępna w wyższym planie'}
        itemIndexToHighlight={5}
      />
    ) : (
      <div className="text-center py-4">{loading ? null : 'Brak historii'}</div>
    );
  }

  function getIconBg(item: History) {
    if (grapeColor) {
      if (item?.status === 'ADDED_BOX')
        return grapeColor === GrapeColor.WHITE ? Color.Yellow : Color.Red;
    }
    return item?.status === 'ADDED_WINE' ? Color.Green : Color.Blue;
  }

  if (!accessInfo.hasNormalAccess) history = [];

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <ContentWrapper noBoarder>
        {history?.length ? (
          <TimelineSection>
            {history?.map((item, index) => {
              const subHeader =
                (item?.actionType ? item?.actionType + ' ' : '') +
                (item?.message ? item?.message : '');
              return (
                <TimelineElement
                  historyItem={item}
                  inverted={item?.status === 'ADDED_WINE'}
                  key={index}
                  iconBg={getIconBg(item)}
                  header={t(`history.status.${item?.status}`)}
                  date={
                    item?.status === 'CREATED' || item?.status === 'ADDED_WINE'
                      ? FromApiConverter.convertDate(item?.createdItemDate)
                      : FromApiConverter.convertDateTime(item?.createdItemDate)
                  }
                  subHeader={subHeader}
                />
              );
            })}
          </TimelineSection>
        ) : (
          getFallbackInfo()
        )}
      </ContentWrapper>
    </SectionWrapper>
  );
};

HarvestHistory.propTypes = {
  history: PropTypes.array.isRequired
};

export default HarvestHistory;
