import React, { useRef, useState } from 'react';
import { TankOutput } from '../../../types/WineStorage';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import { SimpleTankOutputList } from './SimpleTankOutputList';
import { ServiceError, StatusType } from '../../../../../../../types/Service';
import { useTranslation } from 'react-i18next';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { useTankOutputService } from '../../../hooks/useTankOutputService';
import { EntityLiveStatus } from '../../../../../../../components/common/enums/EntityLiveStatus';
import { useConfirmation } from '../../../../../../notifications/useConfirmation';
import log from 'loglevel';

export const TankOutputList: React.FC<{ tankOutputs: TankOutput[] }> = ({ tankOutputs = [] }) => {
  const { setResult, wineStorage } = useWineStorageContext();
  const [error, setError] = useState<ServiceError>();
  const { t } = useTranslation();
  const serviceRef = useRef(useTankOutputService());
  const [loading, setLoading] = useState(false);

  const showConfirmation = useConfirmation();

  const removeOutput = (entity: TankOutput) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemove(entity.id)
    });
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    serviceRef.current
      .del(id)
      .then((response) => {
        setResult({ status: StatusType.loaded, payload: response });
      })
      .then(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      })
      .catch((error) => {
        log.error(error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {tankOutputs?.length || loading || wineStorage?.id == undefined ? (
        <SimpleTankOutputList
          tankOutputs={tankOutputs}
          loading={loading || wineStorage?.id == undefined}
          removeOutput={removeOutput}
          title={t('wineStorage.tank.output')}
          removeDisabled={wineStorage.liveStatus === EntityLiveStatus.ARCHIVED}
        >
          <FormErrorMessage error={error} messageType={'details'} />
        </SimpleTankOutputList>
      ) : null}
    </>
  );
};
