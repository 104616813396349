import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Input } from 'reactstrap';

import FormValidator from '../components/forms/FormValidator.js';
import { AuthService } from '../features/platform/AuthService';
import { AuthContext } from '../features/platform/AuthContext';
import { WelcomeCard } from '../components/common/cards/WelcomeCard';
import { SectionWrapper } from '../layouts/SectionWrapper';
import log from 'loglevel';

class Login extends Component {
  static contextType = AuthContext;

  state = {
    formLogin: {
      email: '',
      password: ''
    },
    loading: false
  };

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  validateOnChange = (event) => {
    const input = event.target;
    const form = input.form;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  onSubmit = (e) => {
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName));
    const {
      formLogin: { email, password }
    } = this.state;
    e.preventDefault();

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });
    if (!hasError) {
      this.setState({
        loading: true
      });
      AuthService.login(email, password)
        .then(this.handleLogin)
        .catch((res) => log.error(res));
    }
  };

  handleLogin = (principal) => {
    log.debug('LoginForm::handleLogin', principal);
    this.context.setPrincipal(principal);

    this.setState(() => ({
      loading: false,
      showErrors: false,
      failed: !principal.login
    }));
    principal?.login && this.props.history.push(principal.realms[0]);
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  render() {
    return (
      <WelcomeCard>
        <SectionWrapper loading={this.state.loading}>
          <p className="text-center py-2">ZALOGUJ SIĘ ABY KONTYNUOWAĆ</p>
          <form className="mb-3" name="formLogin" onSubmit={this.onSubmit}>
            <div className="form-group">
              <label className="text-muted" htmlFor="id-username">
                Nazwa użytkownika
              </label>
              <div className="input-group with-focus">
                <Input
                  type="email"
                  name="email"
                  className="border-right-0"
                  placeholder="Wprowadź email"
                  invalid={
                    this.hasError('formLogin', 'email', 'required') ||
                    this.hasError('formLogin', 'email', 'email')
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "email"]'
                  value={this.state.formLogin.email}
                />
                <div className="input-group-append">
                  <span className="input-group-text text-muted bg-transparent border-left-0">
                    <em className="fa fa-envelope"></em>
                  </span>
                </div>
                {this.hasError('formLogin', 'email', 'required') && (
                  <span className="invalid-feedback">Pole wymagane</span>
                )}
                {this.hasError('formLogin', 'email', 'email') && (
                  <span className="invalid-feedback">Podaj prawidłowy email</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="text-muted" htmlFor="id-username">
                Hasło
              </label>
              <div className="input-group with-focus">
                <Input
                  type="password"
                  id="id-password"
                  name="password"
                  className="border-right-0"
                  placeholder="Wprowadź hasło"
                  invalid={this.hasError('formLogin', 'password', 'required')}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.formLogin.password}
                />
                <div className="input-group-append">
                  <span className="input-group-text text-muted bg-transparent border-left-0">
                    <em className="fa fa-lock"></em>
                  </span>
                </div>
                <span className="invalid-feedback">Pole wymagane</span>
              </div>
            </div>
            {this.state.failed && (
              <small className="text-danger">Nieprawidłowy login lub hasło</small>
            )}
            <div className="clearfix">
              {/*<div className="checkbox c-checkbox float-left mt-0">*/}
              {/*    <label>*/}
              {/*        <input type="checkbox" value="" name="remember"/>*/}
              {/*        <span className="fa fa-check"></span>Remember Me</label>*/}
              {/*</div>*/}
              <div className="float-right">
                <Link to="/recover" className="text-muted">
                  Zapomniałeś hasła?
                </Link>
              </div>
            </div>
            <button
              className="btn btn-block btn-primary mt-3"
              type="submit"
              disabled={this.props.loading}
            >
              Zaloguj
            </button>
          </form>
          <p className="pt-3 text-center">Chcesz dołączyć?</p>
          <NavLink to="/welcome/register" className="btn btn-block btn-secondary">
            Utwórz konto
          </NavLink>
        </SectionWrapper>
      </WelcomeCard>
    );
  }
}

export default Login;
