import { AccountHeaderInfo } from './AccountHeaderInfo';
import React, { useState } from 'react';
import useAccountService from '../hooks/useAccountService';
import { useAuthContext } from '../../platform/AuthContext';
import { SectionWrapper } from '../../../layouts/SectionWrapper';
import { ServiceError } from '../../../types/Service';
import { FormErrorMessage } from '../../../components/notifications/FormErrorMessage';
import { ResponseError } from '../../error/ResponseError';
import { AuthService } from '../../platform/AuthService';
import { usePushHistory } from '../../../hooks/usePushHistory';
import log from 'loglevel';

export const ProfileTab: React.FC<{ setLoading: (val: boolean) => void; loading: boolean }> = ({
  setLoading,
  loading
}) => {
  const {
    setPrincipal,
    principal,
    subscriptionInfo: { activeSubscription: subscription }
  } = useAuthContext();

  const service = useAccountService();
  const [error, setError] = useState<ServiceError>();
  const [name, setName] = useState(principal.name);
  const [companyName, setCompanyName] = useState(principal.companyName);
  const [tin, setTin] = useState(principal.companyTin);
  const { pushHistory } = usePushHistory();

  function handleSubmit() {
    if (name !== principal.name) {
      setLoading(true);
      service
        .updateUser({
          name,
          login: principal.login
        })
        .then(() => {
          setError(null);
        })
        .then(() => {
          AuthService.getUserInfo()
            .then((res) => setPrincipal(res))
            .catch(() => {
              pushHistory(`/mv/error`);
            });
        })
        .catch((error) => {
          setError(new ResponseError(error));
        })
        .finally(() => setLoading(false));
    }
    if (companyName !== principal.companyName || tin !== principal.companyTin) {
      setLoading(true);
      service
        .updateCompany({
          name: companyName,
          tin
        })
        .then(() => setError(null))
        .then(() => {
          AuthService.getUserInfo()
            .then((res) => setPrincipal(res))
            .catch(() => {
              pushHistory(`/mv/error`);
            });
        })
        .catch((error) => {
          setError(new ResponseError(error));
          log.error(error);
        })
        .finally(() => setLoading(false));
    }
  }

  function submitDisabled() {
    return (
      name === principal.name &&
      companyName === principal.companyName &&
      tin === principal.companyTin
    );
  }

  return (
    <SectionWrapper loading={loading}>
      <div className="card">
        <AccountHeaderInfo subscription={subscription} title={'Profil'} />
        <div className="card-body">
          <form action="#">
            {/*<div className="form-group">*/}
            {/*  <label>Picture</label>*/}
            {/*  <input*/}
            {/*    className="form-control filestyle"*/}
            {/*    type="file"*/}
            {/*    data-input="false"*/}
            {/*    data-classbutton="btn btn-secondary"*/}
            {/*    data-classinput="form-control inline"*/}
            {/*    data-text="Upload new picture"*/}
            {/*    data-icon="<span class='fa fa-upload mr-2'></span>"*/}
            {/*    id="filestyle-0"*/}
            {/*    tabIndex={-1}*/}
            {/*    style={{*/}
            {/*      position: 'absolute',*/}
            {/*      clip: 'rect(0px, 0px, 0px, 0px)'*/}
            {/*    }}*/}
            {/*  />*/}
            {/*  <div className="bootstrap-filestyle input-group">*/}
            {/*    <div*/}
            {/*      style={{*/}
            {/*        position: 'absolute',*/}
            {/*        width: '100%',*/}
            {/*        height: '35.3906px',*/}
            {/*        zIndex: -1*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    <span className="group-span-filestyle " tabIndex={0}>*/}
            {/*      <label*/}
            {/*        htmlFor="filestyle-0"*/}
            {/*        style={{ marginBottom: 0 }}*/}
            {/*        className="btn btn-secondary "*/}
            {/*      >*/}
            {/*        <span className="fa fa-upload mr-2" />*/}
            {/*        <span className="buttonText">Upload new picture</span>*/}
            {/*      </label>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="form-group">
              <label>Właściciel</label>
              <input
                className="form-control"
                type="text"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Nazwa winnicy</label>
              <input
                className="form-control"
                type="text"
                defaultValue={principal.companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>NIP (tylko PL)</label>
              <input
                className="form-control"
                type="text"
                id={'tin'}
                name={'tin'}
                defaultValue={principal.companyTin}
                onChange={(e) => setTin(e.target.value)}
              />
              <FormErrorMessage error={error} messageType={'fieldError'} fieldName={'tin'} />
            </div>
            <div className="form-group">
              <label>Login</label>
              <input className="form-control" type="text" defaultValue={principal.login} disabled />
            </div>
            <button
              className="btn btn-info"
              type="button"
              onClick={handleSubmit}
              disabled={submitDisabled()}
            >
              Zapisz ustawienia
            </button>
          </form>
          <FormErrorMessage error={error} messageType={'details'} />
        </div>
      </div>
    </SectionWrapper>
  );
};
