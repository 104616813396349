import React, { useRef, useState } from 'react';
import { TankEntry } from '../../../types/WineStorage';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import SimpleTankEntryList from './SimpleTankEntryList';
import { ServiceError, StatusType } from '../../../../../../../types/Service';
import { useTankEntryService } from '../../../hooks/useTankEntryService';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { usePushHistory } from '../../../../../../../hooks/usePushHistory';
import { WineProduction } from '../../../../wine/types/WineProduction';
import { EntityLiveStatus } from '../../../../../../../components/common/enums/EntityLiveStatus';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../../../../../notifications/useConfirmation';
import log from 'loglevel';

export const TankEntryList: React.FC<{ tankEntries: TankEntry[]; loading: boolean }> = ({
  tankEntries,
  loading = []
}) => {
  const { setResult, wineStorage } = useWineStorageContext();
  const [error, setError] = useState<ServiceError>();
  const serviceRef = useRef(useTankEntryService());
  const [loadingEntries, setLoadingEntries] = useState(false);
  const { pushHistory } = usePushHistory();

  const { t } = useTranslation();
  const showConfirmation = useConfirmation();

  const remove = (entity: TankEntry) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemove(entity.id)
    });
  };

  const handleRemove = (id: number) => {
    setLoadingEntries(true);
    serviceRef.current
      .del(id)
      .then((response) => {
        setResult({ status: StatusType.loaded, payload: response });
      })
      .then(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      })
      .catch((error) => {
        log.error(error);
        setError(error);
      })
      .finally(() => {
        setLoadingEntries(false);
      });
  };

  const info = (wineProduction: WineProduction) => {
    pushHistory(`/mv/wine_production/info/${wineProduction.id}`);
  };

  return (
    <>
      {tankEntries?.length || loadingEntries || wineStorage?.id == undefined ? (
        <SimpleTankEntryList
          tankEntries={tankEntries}
          loading={loading || loadingEntries}
          remove={remove}
          info={info}
          removeDisabled={wineStorage.liveStatus === EntityLiveStatus.ARCHIVED}
        >
          <FormErrorMessage error={error} messageType={'details'} />
        </SimpleTankEntryList>
      ) : null}
    </>
  );
};
