import React, { useContext, useState } from 'react';
import { EntityLiveStatus } from './enums/EntityLiveStatus';
import useClickOutside from '../../hooks/useClickOutside';
import { AuthContext } from '../../features/platform/AuthContext';
import { WineProductionPresentation } from '../../features/winery/components/wine/types/WineProduction';
import { WinePresentation } from '../../features/winery/components/wine/types/Wine';
import { Harvest } from '../../features/winery/components/harvest/types/Harvest';
import { Parcel } from '../../features/winery/components/parcel/types/Parcel';
import { Grapevine } from '../../features/winery/components/grapevine/types/Grapevine';
import { SubscriptionType } from '../../types/Subscription';

type EntityForActions =
  | WineProductionPresentation
  | WinePresentation
  | Harvest
  | Parcel
  | Grapevine;

interface Props {
  entity: EntityForActions;
  actions: {
    proceed?: (entity: EntityForActions, otherEntityName?: string) => void;
    remove?: (entity: EntityForActions) => void;
    info?: (entity: EntityForActions) => void;
    archive?: (entity: EntityForActions) => void;
    revertArchive?: (entity: EntityForActions) => void;
    toProduction?: (entity: EntityForActions) => void;
  };
  subscriptionTypeToArchive?: SubscriptionType;
  triggerRemoveCallback?: () => void;
  otherEntityName?: string;
}

const ListActions: React.FC<Props> = ({
  entity,
  actions: { proceed, remove, info, archive, revertArchive, toProduction },
  triggerRemoveCallback,
  otherEntityName,
  subscriptionTypeToArchive
}) => {
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const { wrapperRef: clickOutsideRef } = useClickOutside(() => {
    setIsOpen(false);
  });

  const renderButton = (
    disabled: boolean,
    onClick,
    label: string,
    color?: string,
    considerLock?: boolean
  ) => {
    const showLockIcon =
      subscriptionTypeToArchive && subscriptionTypeToArchive === SubscriptionType.PREMIUM
        ? !accessInfo.hasPremiumAccess
        : !accessInfo.hasNormalAccess;
    return (
      <button
        type="button"
        tabIndex={0}
        className={'dropdown-item ' + (disabled ? 'disabled' : '') + (color || '')}
        onClick={onClick}
      >
        {label}
        {considerLock ? (
          <>
            {showLockIcon ? <em className={'icon-lock ml-1'} /> : null}
            <sup className="text-yellow ml-1">
              {' '}
              {subscriptionTypeToArchive && subscriptionTypeToArchive === SubscriptionType.PREMIUM
                ? 'premium'
                : 'pro'}
            </sup>
          </>
        ) : null}
      </button>
    );
  };

  const getMainButton = () => {
    if (toProduction && entity?.liveStatus !== EntityLiveStatus.ARCHIVED) {
      return (
        <button className="btn btn-info" onClick={() => toProduction(entity)}>
          Stwórz wino z całości
        </button>
      );
    } else if (entity?.liveStatus === EntityLiveStatus.ARCHIVED) {
      return (
        <button className="btn btn-info" onClick={() => mainButtonAction(entity)}>
          Podgląd
        </button>
      );
    }
    return (
      <button className="btn btn-info" onClick={() => mainButtonAction(entity)}>
        {info ? 'Zarządzaj' : 'Edytuj'}
      </button>
    );
  };

  const mainButtonAction = info || proceed;
  return (
    <div
      id="split-button-basic-1"
      className={'btn-group' + (isOpen ? ' show' : '')}
      ref={clickOutsideRef}
    >
      {getMainButton()}
      <button
        type="button"
        aria-haspopup="true"
        aria-expanded="true"
        className="dropdown-toggle btn btn-info"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="sr-only">Toggle Dropdown</span>
      </button>
      <div
        tabIndex={-1}
        role="menu"
        aria-hidden="false"
        className={'dropdown-menu' + (isOpen ? ' show' : '')}
        style={{
          position: 'absolute',
          willChange: 'transform',
          top: '0px',
          left: '-100px',
          transform: 'translate3d(76px, 33px, 0px)'
        }}
        onClick={() => setIsOpen(false)}
      >
        {(info || toProduction) &&
          entity?.liveStatus !== EntityLiveStatus.ARCHIVED &&
          renderButton(false, () => proceed(entity, otherEntityName), 'Edytuj')}
        {archive &&
          entity?.liveStatus !== EntityLiveStatus.ARCHIVED &&
          renderButton(false, () => archive(entity), 'Archiwizuj', null, true)}
        {revertArchive &&
          entity?.liveStatus === EntityLiveStatus.ARCHIVED &&
          renderButton(false, () => revertArchive(entity), 'Cofnij archiwizację', null, true)}
        {info && <div tabIndex={-1} className="dropdown-divider" />}
        {remove
          ? renderButton(
              false,
              () => {
                remove(entity);
                triggerRemoveCallback?.();
              },
              'Usuń',
              'text-danger'
            )
          : null}
      </div>
    </div>
  );
};

export default ListActions;
