import { useEffect, useState } from 'react';
import { Service, ServiceError, ServiceWorking, StatusType } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import { SelectOption } from '../../../../../types/SelectOption';
import useWineProductionService from './useWineProductionService';
import { WineProduction, WineProductionPresentation } from '../../wine/types/WineProduction';
import { Tank } from '../../tank/types/Tank';

export const useWineProductionsReceiver = (value?) => {
  const service = useWineProductionService();
  const [result, setResult] = useState<Service<WineProductionPresentation[]>>({
    status: StatusType.loading
  });
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<
    (SelectOption & { liters: number; tank: Partial<Tank> }) | string
  >('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (value) {
      setSelected(
        value?.id
          ? {
              value: value.id,
              label: value.label ? value.label : value.name,
              liters: value.liters,
              tank: { id: value.tankId, number: value.tankNumber, capacity: value.tankCapacity }
            }
          : ''
      );
    }
    const workingResult = result as ServiceWorking<WineProductionPresentation[]>;
    if (workingResult?.payload || loading) {
      return;
    }
    setLoading(true);
    service
      ?.getListWithPositiveLiters()
      .then((response) => {
        setResult({ status: StatusType.loaded, payload: response });
        setOptions(
          response?.map((p) => ({
            value: p.id,
            label: p.name,
            liters: p.liters,
            tank: { id: p.tankId, number: p.tankNumber, capacity: p.tankCapacity }
          }))
        );
      })
      .catch((error) => setResult(new ResponseError(error) as ServiceError))
      .finally(() => setLoading(false));
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return { result, options, selected, loading: result.status === StatusType.loading };
};
