import log from 'loglevel';

const apiUrl = '/auth';

const transformResponse = (res) => {
  log.debug('AuthApi:transformResponse', res);
  return res.ok
    ? res.json()
    : res.json().then((e) => {
        throw e;
      });
};

export const AuthApi = {
  get: () => {
    log.debug('AuthApi::get', apiUrl);

    return fetch(apiUrl, {
      credentials: 'include'
    }).then(transformResponse);
  },

  login: (login, password, fields) => {
    log.debug('AuthApi::login', apiUrl);

    return fetch(apiUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ login: login, password: password }),
      ...fields
    }).then(transformResponse);
  },

  register: (email, username, companyName, fields) => {
    log.debug('AuthApi::register', apiUrl + '/register');

    return fetch(apiUrl + '/register', {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ login: email, username, companyName }),
      ...fields
    }).then(transformResponse);
  },

  logout: () => {
    log.debug('AuthApi::logout', apiUrl);

    return fetch(apiUrl, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json'
      }
    }).then(transformResponse);
  },

  recover: (login, fields) => {
    const url = apiUrl + '/requestResetPassword';
    log.debug('AuthApi::recover', url);

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ login: login, password: '' }),
      ...fields
    }).then(transformResponse);
  },

  validate: (token, fields) => {
    const url = apiUrl + '/resetPassword/' + token;
    log.debug('AuthApi::recover', url);
    return fetch(url, {
      credentials: 'include',
      ...fields
    }).then(transformResponse);
  },

  reset: (token, password, fields) => {
    const url = apiUrl + '/resetPassword';
    log.debug('AuthApi::reset', url);

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: token, newPassword: password }),
      ...fields
    }).then(transformResponse);
  }
};
