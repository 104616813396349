import React, { useEffect, useRef, useState } from 'react';
import { BoxWithGrapes } from '../../HarvestInfo/box/types/BoxWithGrapes';
import useBoxService from '../../HarvestInfo/box/hooks/useBoxService';
import { BoxesInHarvestListTable } from './BoxesInHarvestListTable';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../../../../../notifications/useConfirmation';
import { useHarvestContext } from '../../../context/HarvestContext';
import { StatusType } from '../../../../../../../types/Service';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';
import log from 'loglevel';

interface IProps {
  disposed: boolean;
  reloadHarvest: () => void;
}

export const BoxesInHarvestListCard: React.FC<IProps> = ({ disposed, reloadHarvest }) => {
  const boxServiceRed = useRef(useBoxService());
  const [loading, setLoading] = useState(true);
  const [boxes, setBoxes] = useState<BoxWithGrapes[]>([]);
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const { setHarvestResult, harvest } = useHarvestContext();

  useEffect(() => {
    if (loading) {
      boxServiceRed.current
        .getList()
        .then((response) => {
          setBoxes(response);
        })
        .finally(() => setLoading(false));
    }
  }, [loading]);

  const removeBox = (box: BoxWithGrapes) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemove(box.id)
    });
  };

  function handleRemove(id: number) {
    setLoading(true);
    boxServiceRed.current
      .del(id)
      .then((res) => {
        setBoxes((prevState) => prevState.filter((box) => box.id !== res.id));
        setHarvestResult({
          status: StatusType.loaded,
          payload: {
            ...harvest,
            weightOfGrapes: harvest.weightOfGrapes - (res.weightOfFullBox - res.weightOfEmptyBox)
          }
        });
      })
      .catch((error) => {
        log.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <BoxesInHarvestListTable
        boxes={boxes}
        removeBox={removeBox}
        disposed={disposed}
        reloadHarvest={reloadHarvest}
      />
    </SectionWrapper>
  );
};
