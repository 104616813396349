import React, { useEffect, useRef } from 'react';

export const DivDropped = ({
  open,
  label,
  children
}: {
  open: boolean;
  label?: string;
  children: React.ReactNode;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setMaxHeight = () => {
      if (contentRef.current) {
        contentRef.current.style.maxHeight = open ? `${contentRef.current.scrollHeight}px` : '0px';
        contentRef.current.style.opacity = open ? '1' : '0';
      }
      contentRef.current.style.overflow = open ? '' : 'hidden';
    };

    // Set maxHeight initially when `open` changes
    setMaxHeight();

    // Observe changes in the content size and reset maxHeight
    const observer = new MutationObserver(setMaxHeight);
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [open, children]);

  return (
    <div className="collapse-container">
      <div ref={contentRef} className={`collapse-content ${open ? 'open' : ''}`}>
        {label && (
          <h6 className="p-3 text-muted text-uppercase font-size-md opacity-5 font-weight-normal">
            {label}
          </h6>
        )}
        {children}
      </div>
    </div>
  );
};
