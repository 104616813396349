import React, { useRef, useState } from 'react';
import { TaxBandsPlacement } from '../../../types/WineStorage';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import SimpleTaxBandsPlacementsList from './SimpleTaxBandsPlacementsList';
import { ServiceError, StatusType } from '../../../../../../../types/Service';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { useTaxBandsPlacementService } from '../../../hooks/useTaxBandsPlacementService';
import { EntityLiveStatus } from '../../../../../../../components/common/enums/EntityLiveStatus';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../../../../../notifications/useConfirmation';
import log from 'loglevel';

export const TaxBandsPlacementsList: React.FC<{
  taxBandPlacements: TaxBandsPlacement[];
  loading: boolean;
}> = ({ taxBandPlacements, loading }) => {
  const { setResult, wineStorage } = useWineStorageContext();
  const [error, setError] = useState<ServiceError>();
  const serviceRef = useRef(useTaxBandsPlacementService());
  const [loadingTax, setLoadingTax] = useState(false);

  const { t } = useTranslation();
  const showConfirmation = useConfirmation();

  const removeTaxBandPlacement = (entity: TaxBandsPlacement) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemove(entity.id)
    });
  };

  const handleRemove = (id: number) => {
    setLoadingTax(true);
    serviceRef.current
      .del(id)
      .then((response) => {
        setResult({ status: StatusType.loaded, payload: response });
      })
      .then(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      })
      .catch((error) => {
        log.error(error);
        setError(error);
      })
      .finally(() => {
        setLoadingTax(false);
      });
  };

  return (
    <>
      {taxBandPlacements?.length || wineStorage?.id == undefined ? (
        <SimpleTaxBandsPlacementsList
          taxBandsPlacements={taxBandPlacements}
          loading={loading || loadingTax}
          removeDisabled={wineStorage.liveStatus === EntityLiveStatus.ARCHIVED}
          remove={removeTaxBandPlacement}
          wineStorageId={wineStorage?.id}
        >
          <FormErrorMessage error={error} messageType={'details'} />
        </SimpleTaxBandsPlacementsList>
      ) : null}
    </>
  );
};
