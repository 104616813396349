import { SubscriptionType } from '../../../../../types/Subscription';
import { PlanItem } from '../../plans/PlanItem';
import React from 'react';
import { SubscriptionPlan } from '../../../data/Plans';
import { useTranslation } from 'react-i18next';

export const PickerPlanStepOne: React.FC<{
  plan: SubscriptionPlan;
  header: string;
  info: string;
  itemIndexToHighlight?: number | number[];
}> = ({ plan, header, info, itemIndexToHighlight }) => {
  const { t } = useTranslation();

  function shouldHighlight(index: number) {
    if (!itemIndexToHighlight) return false;
    if (typeof itemIndexToHighlight === 'number') {
      return index === itemIndexToHighlight;
    } else {
      return itemIndexToHighlight.includes(index);
    }
  }

  return (
    <>
      <div className={'h5 text-bold text-gradient-orange-blue'}>{header}</div>
      <div className={'h3 text-bold text-gradient-blue-purple'}>{info}</div>
      <div className={'text-gradient-blue-purple mb-3'}>{plan.description}</div>
      {plan.subscriptionType !== SubscriptionType.BASIC ? (
        <div className={'text-bold mb-2'}>
          Co znajdziesz w {t(`plans.subscription.type.${plan.subscriptionType}`)}
        </div>
      ) : null}
      <div
        className={'mb-1 ' + (plan.subscriptionType === SubscriptionType.BASIC ? 'text-bold' : '')}
      >
        {plan.containsItems[0]}
      </div>
      {plan.containsItems.map(
        (item, index) =>
          index !== 0 && (
            <PlanItem title={item} include={true} key={index} highlight={shouldHighlight(index)} />
          )
      )}
    </>
  );
};
