import React, { useState } from 'react';
import { WelcomeCard } from '../components/common/cards/WelcomeCard';
import { Input } from 'reactstrap';
import { Button } from '../components/common/buttons/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthService } from '../features/platform/AuthService';
import log from 'loglevel';

const Recover = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onChange = ({ target: { value } }) => {
    setEmail(value);
    // setError(!validator?.isEmail?.(value));
  };

  const onSubmit = () => {
    const abortController = new AbortController();

    setLoading(true);
    !error &&
      AuthService.recover(email, { signal: abortController.signal })
        .then(() => {
          setEmail('');
          setError(false);
          setSuccess(true);
        })
        .catch((res) => log.error(res))
        .finally(() => setLoading(false));
  };

  return (
    <WelcomeCard>
      <p className="text-center py-2">{t('password.RESET_PASSWORD')}</p>
      <form name="formRecover" onSubmit={onSubmit}>
        {success ? (
          <p className="text-center py-2 text-success">{t('password.reset.success')}</p>
        ) : (
          <>
            <div className="form-group">
              <div className="input-group with-focus">
                <Input
                  type="email"
                  name="email"
                  className="border-right-0"
                  placeholder="Wprowadź email"
                  invalid={error}
                  disabled={success}
                  onChange={onChange}
                  data-validate='["required", "email"]'
                  value={email}
                />
                <div className="input-group-append">
                  <span className="input-group-text text-muted bg-transparent border-left-0">
                    <em className="fa fa-envelope"></em>
                  </span>
                </div>
                {error && <span className="invalid-feedback">{t('email.validation.correct')}</span>}
              </div>
            </div>
            <Button
              onClick={onSubmit}
              label={'Resetuj'}
              className={'btn btn-danger btn-block'}
              disabled={!email || error || loading}
              loading={loading}
            />
          </>
        )}
        <div className="d-flex">
          <div className="mt-1 ml-10 ml-auto">
            <Link to="/welcome/login" className="text-muted">
              <small>{t('password.back.to.login')}</small>
            </Link>
          </div>
        </div>
      </form>
    </WelcomeCard>
  );
};

export default Recover;
